import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './SectionMessage.module.css';
import ceo from '../../../assets/ceo.jpg';

const SectionMessage = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {/* 代表ごあいさつ */}
      <div className={css.title}>
        <FormattedMessage id="SectionMessage.titleLineOne" />
      </div>
      <div className={css.texts}>
        <div className={css.textImage}>
          <img src={ceo} />
        </div>
        <div className={css.text}>
          <p>こんにちは、株式会社アンティークコインギャラリアの代表取締役、渡辺です。</p>
          <p>
            この度、株式会社アンティークコインギャラリアを運営する傍ら、コインのプラットフォーム事業にチャレンジすべく、新法人を立ち上げました。
          </p>
          <p>コインは1000年以上マーケットが存在し人類を長く魅了し続けています。</p>
          <p>
            数奇な縁でこのコイン業界に携わらせて頂いている私が、自分の経験を持ってコイン業界にもっと貢献できることは何かと真剣に考えたときに出てきた答えがこのマーケットプレイス事業でした。
          </p>
          <p>
            コインの世界は買うことは簡単ですが、売ることは簡単ではありません。個人間売買ではトラブルが心配ですし、オークションで換金するには時間がかかりすぎます。
          </p>
          <p>
            そこでコイン専用でプロが仲介するマーケットプレイスがあればコレクター、投資家の皆さんの活動をより支援できるのではないか？と考えました。
          </p>
          <p>
            まだまだ、私自身も経験不足で至らない点も多々あるかと思いますが、コイン業界への貢献、恩返しが少しでもできればと考えております。
          </p>
        </div>
      </div>
    </div>
  );
};

SectionMessage.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionMessage.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionMessage;
