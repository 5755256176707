import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './SectionFeatures.module.css';
import { lazyLoadWithDimensions } from '../../../util/contextHelpers';
import privacy from '../../../assets/privacy.svg';
import guarantee from '../../../assets/guarantee.svg';
import trouble from '../../../assets/trouble.svg';

class IconImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}

const LazyImage = lazyLoadWithDimensions(IconImage);

const SectionFeatures = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {/* 3つの特徴: features */}
      <div className={css.title}>
        <FormattedMessage id="SectionFeatures.titleLineOne" />
      </div>

      <div className={css.steps}>
        <div className={css.step}>
          <div className={css.imageWrapper}>
            <div className={css.aspectWrapper}>
              <LazyImage src={privacy} alt={'one'} className={css.searchImage} />
            </div>
          </div>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionFeatures.part1Title" />
          </h2>
          <p className={css.stepText}>
            <FormattedMessage id="SectionFeatures.part1Text" />
          </p>
        </div>

        <div className={css.step}>
          <div className={css.imageWrapper}>
            <div className={css.aspectWrapper}>
              <LazyImage src={guarantee} alt={'one'} className={css.searchImage} />
            </div>
          </div>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionFeatures.part2Title" />
          </h2>
          <p className={css.stepText}>
            <FormattedMessage id="SectionFeatures.part2Text" />
          </p>
        </div>

        <div className={css.step}>
          <div className={css.imageWrapper}>
            <div className={css.aspectWrapper}>
              <LazyImage src={trouble} alt={'one'} className={css.searchImage} />
            </div>
          </div>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionFeatures.part3Title" />
          </h2>
          <p className={css.stepText}>
            <FormattedMessage id="SectionFeatures.part3Text" />
          </p>
        </div>
      </div>
    </div>
  );
};

SectionFeatures.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionFeatures.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFeatures;
