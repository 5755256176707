import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './SectionAnonymity.module.css';
import anonymousImg from '../../../assets/anonymous_img.jpg';

const SectionAnonymity = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {/* 匿名性について: anonymity */}
      <div className={css.title}>
        <FormattedMessage id="SectionAnonymity.titleLineOne" />
      </div>

      <div className={css.steps}>
        <div className={css.step}>
          <p>
            <FormattedMessage id="SectionAnonymity.text" />
          </p>
        </div>
      </div>
      <div>
        <img src={anonymousImg} className={css.stepImage} />
      </div>
    </div>
  );
};

SectionAnonymity.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionAnonymity.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionAnonymity;
