import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './SectionCompany.module.css';

const SectionCompany = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {/* 運営会社: company */}
      <div className={css.title}>
        <FormattedMessage id="SectionCompany.titleLineOne" />
      </div>

      <div className={css.steps}>
        <div className={css.step}>
          <p>
            <FormattedMessage id="SectionCompany.text" />
          </p>
        </div>
      </div>
      {/* テーブル */}
      <div className={css.tableContent}>
        <table>
          <tbody>
            <tr>
              <th>社名</th>
              <td>株式会社ACM</td>
            </tr>
            <tr>
              <th>代表取締役</th>
              <td>
                渡辺孝祐（
                <a
                  href="https://antique-coin-galleria.com/"
                  target="_blank"
                  className={css.tableLink}
                >
                  アンティークコインギャラリア
                </a>
                代表）
              </td>
            </tr>
            <tr>
              <th>資本金</th>
              <td>300万円</td>
            </tr>
            <tr>
              <th>住所</th>
              <td>東京都港区虎ノ門3-7-11 神谷町アネックス2号館 8階</td>
            </tr>
            <tr>
              <th>問い合わせ</th>
              <td>
                <a
                  href="https://antique-coin-galleria.com/pages/contact"
                  target="_blank"
                  className={css.tableLink}
                >
                  フォーム
                </a>
                よりお問い合わせください
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

SectionCompany.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCompany.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCompany;
