import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './SectionHowToUse.module.css';

const SectionHowToUse = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {/* 利用方法: how to use */}
      <div className={css.title}>
        <FormattedMessage id="SectionHowToUse.titleLineOne" />
      </div>
      <div>
        <h3>
          <FormattedMessage id="SectionHowToUse.buyer" />
        </h3>
        <ol className={css.listBlock}>
          <li>
            <span>1</span> アカウントを作成<span></span>
          </li>
          <li>
            <span>2</span> コインを検索<span></span>
          </li>
          <li>
            <span>3</span> 購入手続き<span></span>
          </li>
          <li className={css.listItem}>
            <span>4</span>
            <div className={css.listItemCol}>
              代金の支払い
              <span>運営会社への支払いとなります</span>
            </div>
            <span></span>
          </li>
          <li>
            <span>5</span> コインの受け取り<span></span>
          </li>
        </ol>

        <h3>
          <FormattedMessage id="SectionHowToUse.seller" />
        </h3>
        <ol className={css.listBlock}>
          <li>
            <span>1</span> アカウントを作成<span></span>
          </li>
          <li className={css.listItem}>
            <span>2</span>
            <div className={css.listItemCol}>
              コインを出品
              <span>コインを手元に置いたまま出品が可能です</span>
            </div>
            <span></span>
          </li>
          <li>
            <span>3</span> 販売通知<span></span>
          </li>
          <li className={css.listItem}>
            <span>4</span>
            <div className={css.listItemCol}>
              コインの出荷
              <span>運営会社への出荷となります</span>
            </div>
            <span></span>
          </li>
          <li>
            <span>5</span> 代金の受け取り<span></span>
          </li>
        </ol>
      </div>
    </div>
  );
};

SectionHowToUse.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowToUse.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowToUse;
