import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './SectionAbout.module.css';
import guarantee from '../../../assets/guarantee.jpg';
import commission from '../../../assets/commission.jpg';

const SectionAbout = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.block}>
        {/* 真贋保証について */}
        <div className={css.blockStep}>
          <div className={css.title}>
            <FormattedMessage id="SectionAbout.authenticityGuaranteeTitleLineOne" />
          </div>

          <div>
            <div className={css.image}>
              <img src={guarantee} />
            </div>
            <div>
              <p>
                <FormattedMessage id="SectionAbout.authenticityGuaranteeText" />
              </p>
            </div>
          </div>
        </div>
        {/* 手数料について */}
        <div className={css.blockStep}>
          <div className={css.title}>
            <FormattedMessage id="SectionAbout.commissionTitleLineOne" />
          </div>

          <div>
            <div className={css.image}>
              <img src={commission} />
            </div>
            <div>
              <p>
                <FormattedMessage id="SectionAbout.commissionText" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionAbout.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionAbout.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionAbout;
